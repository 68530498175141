import React from "react";
import { graphql, useStaticQuery } from "gatsby";
// import Img from "gatsby-image";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Profile from "../components/about/profile";
import { teamData } from "../data/team";

import { MainImage } from "gatsby-plugin-image";
import VideoBlock from "../components/home/video-block";
import CauseBlock from "../components/about/cause-block";

function AboutPage({ data }) {
  const { aboutPage, teamImages, causeImages } = data;

  const boardData = teamData.filter((member) => member.group.includes("board"));
  const advisorsData = teamData.filter((member) =>
    member.group.includes("advisor")
  );

  // Merge member data with gatsby image data
  const boardMembers = [];
  boardData.forEach((member) => {
    teamImages.images.forEach(({ imageData }) => {
      const fullNameSplit = member.fullName.split(" ");
      const fullNameFound = fullNameSplit.every((word) =>
        imageData.name.includes(word.toLowerCase())
      );
      if (fullNameFound) {
        boardMembers.push({ ...member, ...imageData });
      }
    });
  });

  const advisors = [];
  advisorsData.forEach((member) => {
    teamImages.images.forEach(({ imageData }) => {
      const fullNameSplit = member.fullName.split(" ");
      const fullNameFound = fullNameSplit.every((word) =>
        imageData.name.includes(word.toLowerCase())
      );
      if (fullNameFound) {
        advisors.push({ ...member, ...imageData });
      }
    });
  });

  return (
    <div className="bg-dark-purple">
      <Layout>
        <SEO keywords={[`sparkrise`, `about`]} title="About" />

        <article className="mt-12">
          <heading className="container">
            <h2 className="c-h2 text-center text-orange">SparkRise</h2>
          </heading>
          <div className="mx-auto relative flex flex-col flex-wrap items-center justify-center w-1/2 c-h5 text-center text-white">
            <div className="w-full text-center text-white">
              SparkRise is a premier digital community that connects causes, companies, celebrities and engaged activists, collectively united to spark change.
              <br />
              <br />
              The SparkRise platform is your digital home for doing good and keeping track of the impact you’re making – plus so much more!  
            </div>
          </div>

          <VideoBlock data={aboutPage.frontmatter.videoBlock} />

          <div className="text-center">
            <StaticImage
              src="../images/about-images/sparkrise-community-graphic.png"
              width="450"
              contain="scale-down"
              className="mx-auto"
              alt="Socially Engaged SparkRisers"
            />
          </div>

          <heading className="container">
            <h2 className="c-h2 text-center text-orange">SparkRise Cause-Marketing Team</h2>
          </heading>
          <div className="mx-auto relative flex flex-col flex-wrap items-center justify-center w-1/2 c-h5 text-center text-white">
            <div>
              The SparkRise creative team of specialists are poised to match like-minded nonprofits, companies and celebrities to create and execute unique campaigns that foster your existing relationships while engaging new supporters and/or customers.
            <br />
            <br />
              To learn more and contact our diverse team of cause marketing creatives, please contact:&nbsp;&nbsp;
              <a href={`mailto:hello@sparkrise.com`} 
                className="font-bold text-orange hover:opacity-50"
                data-sal="fade"
                data-sal-duration="600"
                data-sal-delay="400"
                data-sal-easing="ease">hello@sparkrise.com</a>
            </div>
          </div>

        </article>
        <br/><br/>
        
        <CauseBlock images={causeImages} data={aboutPage.frontmatter.causeSlides} />
        
        <article className="mt-24 space-y-12 lg:space-y-36">
          <heading className="container mt-24">
            <h2 className="c-h2 text-center text-orange">
              Creative Advisors
            </h2>
          </heading>

          {advisors.map((member, index) => {
            return (
              <Profile
                key={member.name}
                odd={index % 2 === 0}
                name={member.fullName}
                description={member.bio}
                childImageSharp={member.childImageSharp}
              />
            );
          })}
        </article>

        <article className="mt-12">
          <heading className="container">
            <br /><br />
            <h2 className="c-h2 text-center text-orange">Board of Directors</h2>
          </heading>

          {boardMembers.map((member, index) => {
            return (
              <Profile
                key={member.name}
                odd={index % 2 === 0}
                name={member.fullName}
                description={member.bio}
                childImageSharp={member.childImageSharp}
              />
            );
          })}
        </article>

      </Layout>
    </div>
  );
}

export const pageQuery = graphql`
query {
  aboutPage: markdownRemark(frontmatter: {title: {eq: "about"}}) {
    frontmatter {
      title
      videoBlock {
        caption
        videoId
        button {
          text
          url
        }
      }
      causeSlides {
        image
      }
    }
  }
  teamImages: allFile(filter: {relativeDirectory: {eq: "about-images"}, name: {glob: "board-*"}}) {
    images: edges {
      imageData: node {
        name
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
        }
      }
    }
  }
  causeImages: allFile(filter: {relativeDirectory: {eq: "about-images/causes"}, name: {glob: "*"}}) {
    nodes {
      name
      childImageSharp {
        gatsbyImageData
      }
    }
  }
}
`;

export default AboutPage;
