import PropTypes from "prop-types";
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

// import Swiper core and required modules
import { Pagination, Navigation, A11y, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const CauseBlock = ({ data, images }) => {
  return (
    <section id="" className="relative bg-white">
      <br/>
      <h3 className="c-h3 text-center text-orange">Who We've Helped</h3>
      <br/>
      <br/>
      <Swiper
        modules={[Pagination, Navigation, Autoplay, A11y]}
        className=""
        autoplay={{ delay: 10000 }}
        spaceBetween={25}
        slidesPerView={4}
        pagination={{ clickable: true }}
        centeredSlides={true}
        lazy={true}
        loop={true}
      >
        {data.map((slide, i) => {
          const image = images.nodes.find(
            (imageObj) => imageObj.name === slide.image
          );
          return (
            <div>
              <SwiperSlide
                key={"slide-" + i}
                className="flex flex-row bg-white aspect-3/4 sm:aspect-5/3 lg:aspect-5/2"
              >
                <GatsbyImage
                  image={image.childImageSharp.gatsbyImageData}
                  alt={image.name}
                  objectFit="contain"
                  className="w-auto h-auto mx-auto"
                />
              </SwiperSlide>
            </div>
          );
        })}
      </Swiper>
      <br/>
      <br/>
    </section>
  );
};

CauseBlock.propTypes = {
  data: PropTypes.array,
  images: PropTypes.object,
};

export default CauseBlock;
